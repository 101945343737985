import {SET_LINK_AMOCRM_AVITOS} from "../mutations";
import {FormObject} from "../../utils/form-object";

const state = {
    link_amocrm_avito: new FormObject(),
    link_amocrm_avitos: [],
};

const getters = {
    link_amocrm_avito: state => state.link_amocrm_avito || new FormObject(),
    link_amocrm_avitos: state => state.link_amocrm_avitos || [],
};

const actions = {};

const mutations = {
    [SET_LINK_AMOCRM_AVITOS]: (state, link_amocrm_avitos) => {
        if (Array.isArray(link_amocrm_avitos)) {
            state.link_amocrm_avitos = link_amocrm_avitos;
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}