import axios from "axios";
import {LOGIN, REFRESH_TOKEN, REGISTRATION, RESTORE} from "../actions";

const state = {};

const getters = {};

const actions = {
    [LOGIN]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/login',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    localStorage.setItem("access_token", resp.data.data.access_token);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [REGISTRATION]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/registration',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    localStorage.setItem("access_token", resp.data.data.access_token);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [REFRESH_TOKEN]: () => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/refreshToken',
                method: 'POST',
            })
                .then(resp => {
                    localStorage.setItem("access_token", resp.data.data.access_token);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
    [RESTORE]: (_, data) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/auth/restore',
                method: 'POST',
                data: data,
            })
                .then(resp => {
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }
};

const mutations = {};

export default {
    state,
    getters,
    actions,
    mutations
}