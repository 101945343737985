export class Errors {
    constructor() {
        this.errors = [];
    }

    record(errors) {
        this.errors = [];
        if (Array.isArray(errors)) {
            this.errors = errors;
        }
    }

    get(field) {
        for (let error of this.errors) {
            if (error.source && error.source.parameter === field) {
                return error.title;
            }
        }
    }

    clear() {
        this.errors = [];
    }
}