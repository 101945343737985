import {createStore} from 'vuex'
import auth from './modules/auth'
import user from './modules/user'
import project from './modules/project'
import link_amocrm_avito from './modules/link_amocrm_avito'
import amocrm_integration from './modules/amocrm_integration'
import avito_integration from './modules/avito_integration'

export default createStore({
    modules: {
        auth,
        user,
        project,
        link_amocrm_avito,
        amocrm_integration,
        avito_integration,
    }
})
