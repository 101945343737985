<template>
    <div>
        <el-row>
            <el-button @click="addAvito" type="primary">Добавить интеграцию Amocrm - Avito</el-button>
        </el-row>
        <el-row>
            <el-table :data="link_amocrm_avitos" style="width: 100%">
                <el-table-column prop="name" label="Name" width="180"/>
                <el-table-column prop="amocrm_integration_id" label="Интеграция Amocrm"/>
                <el-table-column prop="avito_integration_id" label="Интеграция Avito"/>
                <el-table-column prop="is_active" label="Статус" width="180"/>
                <el-table-column label="Действия">
                    <template #default="scope">
                        <el-button size="small" @click="handleEdit(scope.$index, scope.row)">Редактировать</el-button>
                        <el-button size="small" type="danger">Удалить</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'LinkAmocrmAvito',
        methods: {},
        computed: {
            link_amocrm_avitos() {
                return this.$store.getters.link_amocrm_avitos
            },

        }
    }
</script>

<style scoped>
</style>
