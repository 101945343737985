import axios from 'axios';
import jwt from 'vue-jwt-decode'
import router from "../router"
import store from "../store"
import {REFRESH_TOKEN} from "../store/actions";

export default function setup() {
    axios.interceptors.response.use(function (response) {
        return response;
    }, function (err) {
        if (err && err.response && err.response.status) {
            switch (err.response.status) {
                case 401:
                    localStorage.removeItem('access_token');
                    router.push("/login");
                    break;
            }
        }
        return Promise.reject(err);
    });

    axios.interceptors.request.use(async function (config) {
        let u = new URL(config.url);
        if (!u.pathname.includes("/auth")) {
            let access_token = localStorage.getItem('access_token');
            if (access_token) {
                if (jwt.decode(access_token).exp && new Date((jwt.decode(access_token).exp - 30) * 1000) < new Date()) {
                    await store.dispatch(REFRESH_TOKEN).then((resp) => {
                        access_token = resp.data.data.access_token;
                    })
                }
                config.headers['Authorization'] = `Bearer ${access_token}`;
            }
        }
        return config;
    }, function (error) {
        return Promise.reject(error);
    });
}