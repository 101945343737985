export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
export const REGISTRATION = 'REGISTRATION';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const CONFIRM_REGISTRATION = 'CONFIRM_REGISTRATION';
export const RESTORE = 'RESTORE';

export const GET_USER = 'GET_USER';
export const SAVE_USER = 'SAVE_USER';
export const CHANGE_PASSWORD = 'CHANGE_PASSWORD';
export const ADD_CONTACT = 'ADD_CONTACT';
export const CONFIRM_CONTACT = 'CONFIRM_CONTACT';
export const ADD_NEW_USER = 'ADD_NEW_USER';

export const GET_PROJECT = 'GET_PROJECT';
export const CREATE_PROJECT = 'CREATE_PROJECT';
export const SAVE_PROJECT = 'SAVE_PROJECT';

export const ADD_RIGHT = 'ADD_RIGHT';
export const DELETE_RIGHT = 'DELETE_RIGHT';

export const CONNECT_AMOCRM = 'CONNECT_AMOCRM';
export const CHECK_AMOCRM = 'CHECK_AMOCRM';
export const DISCONNECT_AMOCRM = 'DISCONNECT_AMOCRM';

export const SAVE_AMOCRM_CONFIG = 'SAVE_AMOCRM_CONFIG';
export const SYNC_AMOCRM_CONFIG = 'SYNC_AMOCRM_CONFIG';
export const GET_AMOCRM_CONFIG = 'GET_AMOCRM_CONFIG';
export const DISCONNECT_AMOCRM_CONFIG = 'DISCONNECT_AMOCRM_CONFIG';

