<template>
    <el-row>
        <el-col :span="6" :offset="9">
            <el-card class="box-card" header="Вход">
                <el-form ref="loginFormRef"
                         :model="loginForm"
                         :rules="loginFormRules"
                         label-position="top">
                    <el-form-item label="Email" prop="value" :error="errors.get('value')">
                        <el-input v-model="loginForm.value"/>
                    </el-form-item>
                    <el-form-item label="Пароль" prop="password" :error="errors.get('password')">
                        <el-input v-model="loginForm.password" show-password/>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="login" style="width: 100%">
                            Войти
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button @click="$router.push('/registration')" style="width: 100%">
                            Зарегистрироваться
                        </el-button>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="$router.push('/restore')" style="width: 100%" link>
                            Забыли пароль?
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-card>
        </el-col>
    </el-row>
</template>

<script>
    import {Errors} from "../utils/errors";
    import {LOGIN} from "../store/actions"

    export default {
        name: 'LinkLogin',
        data() {
            return {
                errors: new Errors(),
                loadingLoginForm: false,
                loginForm: {
                    type: "email",
                    value: "",
                    password: "",
                },
                loginFormRules: {
                    value: [{required: true, message: 'Введите email', trigger: 'blur'}],
                    password: [{required: true, message: 'Введите пароль', trigger: 'blur'}],
                },
            }
        },
        methods: {
            login() {
                this.errors.clear();
                this.$refs.loginFormRef.validate((valid) => {
                    if (valid) {
                        this.loadingLoginForm = true;
                        this.$store.dispatch(LOGIN, this.loginForm).then((resp) => {
                            this.loadingLoginForm = false;
                            if (resp.data.data) {
                                this.$router.push("/profile")
                            }
                        }).catch((err) => {
                            this.loadingLoginForm = false;
                            this.errors.record(err.response.data.errors);
                        })
                    }
                });
            }
        },
    }
</script>

<style scoped>
</style>
