export const SET_USER = 'SET_USER';

export const SET_CONTACTS = 'SET_CONTACTS';
export const PUSH_CONTACT = 'PUSH_CONTACT';
export const POP_CONTACT = 'POP_CONTACT';

export const SET_PROJECT = 'SET_PROJECT';
export const SET_PROJECTS = 'SET_PROJECTS';
export const PUSH_PROJECT = 'PUSH_PROJECT';

export const PUSH_RIGHT = 'PUSH_RIGHT';
export const POP_RIGHT = 'POP_RIGHT';

export const SET_RIGHTS = 'SET_RIGHTS';
export const SET_RIGHT = 'SET_RIGHT';

export const SET_AMOCRM = 'SET_AMOCRM';
export const SET_AMOCRM_CONFIG = 'SET_AMOCRM_CONFIG';
export const SET_AMO_CUSTOM_FIELDS = 'SET_AMO_CUSTOM_FIELDS';

export const SET_LINK_AMOCRM_AVITO = 'SET_LINK_AMOCRM_AVITO';
export const SET_LINK_AMOCRM_AVITOS = 'SET_LINK_AMOCRM_AVITOS';
export const SET_AMOCRM_INTEGRATIONS = 'SET_AMOCRM_INTEGRATIONS';
export const SET_AVITO_INTEGRATIONS = 'SET_AVITO_INTEGRATIONS';
