<template>
    <el-row>
        <el-col :span="18" :offset="3">
            <div :style="{boxShadow: `var(--el-box-shadow)`}">
                Профиль {{user}}
            </div>
        </el-col>
    </el-row>
</template>

<script>
    export default {
        name: 'LinkProfile',
        methods: {},
        computed: {
            user() {
                return this.$store.getters.user
            },
        },
    }
</script>

<style scoped>
</style>
