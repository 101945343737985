import _ from 'lodash';

export class FormObject {
  constructor(obj) {
    obj = obj === undefined ? {} : obj;
    this.origin = obj;
    this.object = Object.assign({}, obj);
  }

  isEqual() {
    return _.isEqual(this.origin, this.object);
  }

  reset() {
    this.object = Object.assign({}, this.origin);
  }
}
