import {SET_AMOCRM_INTEGRATIONS} from "../mutations";

const state = {
    amocrm_integrations: [],
};

const getters = {
    amocrm_integrations: state => state.amocrm_integrations || [],
};

const actions = {};

const mutations = {
    [SET_AMOCRM_INTEGRATIONS]: (state, amocrm_integrations) => {
        if (Array.isArray(amocrm_integrations)) {
            state.amocrm_integrations = amocrm_integrations;
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}