import {
    SET_AMOCRM_INTEGRATIONS,
    SET_AVITO_INTEGRATIONS,
    SET_LINK_AMOCRM_AVITOS,
    SET_PROJECT,
    SET_PROJECTS
} from "../mutations";
import {GET_PROJECT} from "../actions";
import axios from "axios";
import {FormObject} from "../../utils/form-object";

const state = {
    project: new FormObject(),
    projects: [],
};

const getters = {
    project: state => state.project || new FormObject(),
    projects: state => state.projects || [],
};

const actions = {
    [GET_PROJECT]: ({commit}, projectId) => {
        return new Promise((resolve, reject) => {
            axios({
                url: `https://${process.env.VUE_APP_HOST}/api/project/${projectId}`,
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_PROJECT, resp.data.data);
                    commit(SET_LINK_AMOCRM_AVITOS, resp.data.data.link_amocrm_avitos);
                    commit(SET_AMOCRM_INTEGRATIONS, resp.data.data.amocrm_integrations);
                    commit(SET_AVITO_INTEGRATIONS, resp.data.data.avito_integrations);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_PROJECT]: (state, project) => {
        state.project = new FormObject({
            id: project.id,
            name: project.name,
        })
    },
    [SET_PROJECTS]: (state, projects) => {
        if (Array.isArray(projects)) {
            state.projects = projects
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}