<template>
    <el-row>
        <el-col :span="18" :offset="3" :style="{boxShadow: `var(--el-box-shadow)`}">
            <div style="padding: 20px;">
                <el-tabs type="border-card">
                    <el-tab-pane label="Интеграции">
                        <link-integration/>
                    </el-tab-pane>
                    <el-tab-pane label="Amocrm">
                        <link-amocrm/>
                    </el-tab-pane>
                    <el-tab-pane label="Avito">
                        <link-avito/>
                    </el-tab-pane>
                    <el-tab-pane label="Настройки">
                        <link-setting/>
                    </el-tab-pane>
                </el-tabs>
            </div>
        </el-col>
    </el-row>
</template>

<script>
    import LinkIntegration from "./project/LinkIntegration";
    import LinkAmocrm from "./project/LinkAmocrm";
    import LinkAvito from "./project/LinkAvito";
    import LinkSetting from "./project/LinkSetting";

    export default {
        name: 'LinkProfile',
        components: {LinkSetting, LinkAvito, LinkAmocrm, LinkIntegration},
        methods: {},
    }
</script>

<style scoped>
</style>
