import {FormObject} from "../../utils/form-object";
import {GET_USER} from "../actions";
import axios from "axios";
import {SET_PROJECTS, SET_USER} from "../mutations";

const state = {
    user: new FormObject(),
};

const getters = {
    user: state => state.user || new FormObject(),
};

const actions = {
    [GET_USER]: ({commit}) => {
        return new Promise((resolve, reject) => {
            axios({
                url: 'https://' + process.env.VUE_APP_HOST + '/api/user',
                method: 'GET',
            })
                .then(resp => {
                    commit(SET_USER, resp.data.data);
                    commit(SET_PROJECTS, resp.data.data.projects);
                    resolve(resp)
                })
                .catch(err => {
                    reject(err)
                })
        })
    },
};

const mutations = {
    [SET_USER]: (state, user) => {
        state.user = new FormObject({
            id: user.id,
            name: user.name,
        })
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}