<template>
    <div>
        <el-row>
            <el-button @click="addAvito" type="primary">Добавить</el-button>
        </el-row>
        <el-row>
            <el-table :data="avito_integrations" style="width: 100%">
                <el-table-column prop="id" label="Активно" width="180"/>
                <el-table-column prop="account_id" label="Name" width="180"/>
                <el-table-column prop="key" label="Address"/>
            </el-table>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'LinkAvito',
        methods: {
            addAvito() {
            },
        },
        computed: {
            avito_integrations() {
                return this.$store.getters.avito_integrations
            },
        }
    }
</script>

<style scoped>
</style>
