<template>
    <el-container>
        <el-header class="header">
            <link-header/>
        </el-header>
        <el-main>
            <router-view/>
        </el-main>
    </el-container>
</template>

<script>
    import LinkHeader from "./components/LinkHeader";

    export default {
        name: 'App',
        components: {
            LinkHeader,
        }
    }
</script>

<style>
    body {
        margin: 0;
    }

    header {
        padding: 0 !important;
    }
</style>
