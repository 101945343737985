<template>
    <div>
        <el-row>
            <el-button @click="addAmocrm" type="primary">Добавить</el-button>
        </el-row>
        <el-row>
            <el-table :data="amocrm_integrations" style="width: 100%">
                <el-table-column prop="id" label="Активно" width="180" />
                <el-table-column prop="account_id" label="Name" width="180" />
                <el-table-column prop="key" label="Address" />
            </el-table>
        </el-row>
    </div>
</template>

<script>
    export default {
        name: 'LinkAmocrm',
        methods: {
            addAmocrm() {

            }
        },
        computed: {
            amocrm_integrations() {
                return this.$store.getters.amocrm_integrations
            },
        }
    }
</script>

<style scoped>
</style>
