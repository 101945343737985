<template>
    <div>
        В разработке
    </div>
</template>

<script>
    export default {
        name: 'LinkAmocrmWebhook',
        methods: {},
        computed: {}
    }
</script>

<style scoped>
</style>
