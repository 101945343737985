<template>
    <router-view/>
</template>

<script>
    import {GET_PROJECT, GET_USER} from "../store/actions"

    export default {
        name: 'LinkMain',
        methods: {
            getUser() {
                this.$store.dispatch(GET_USER).then(() => {
                    let project_id = this.$route.params.project_id;
                    if (project_id) {
                        this.$store.dispatch(GET_PROJECT, project_id)
                    }
                })
            }
        },
        beforeMount() {
            this.getUser();
        }
    }
</script>

<style scoped>
</style>
