import {createRouter, createWebHistory} from 'vue-router'
import LinkProfile from '../components/LinkProfile'
import LinkLogin from '../components/LinkLogin'
import LinkRegistration from '../components/LinkRegistration'
import LinkMain from '../components/LinkMain'
import LinkProject from '../components/LinkProject'

const routes = [
    {
        path: '/login',
        name: 'LinkLogin',
        component: LinkLogin
    },
    {
        path: '/registration',
        name: 'LinkRegistration',
        component: LinkRegistration
    },
    {
        path: '/',
        name: 'LinkMain',
        component: LinkMain,
        children: [
            {
                path: '/project/:project_id',
                name: 'LinkProject',
                component: LinkProject
            },
            {
                path: '/profile',
                name: 'LinkProfile',
                component: LinkProfile
            },
        ]
    },
];

const router = createRouter({
    history: createWebHistory(),
    routes
});

export default router
