<template>
    <div>
        {{project}}
    </div>
</template>

<script>
    export default {
        name: 'LinkSetting',
        methods: {},
        computed: {
            project() {
                return this.$store.getters.project
            },
        }
    }
</script>

<style scoped>
</style>
