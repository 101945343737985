<template>
    <div>
        <el-collapse v-model="activeNames" @change="handleChange">
            <el-collapse-item title="Amocrm - Avito" name="1">
                <link-amocrm-avito/>
            </el-collapse-item>
            <el-collapse-item title="Amocrm - Whatsapp" name="2">
                <link-amocrm-whatsapp/>
            </el-collapse-item>
            <el-collapse-item title="Amocrm - Webhook" name="3">
                <link-amocrm-webhook/>
            </el-collapse-item>
        </el-collapse>
    </div>
</template>

<script>
    import LinkAmocrmAvito from "./integration/LinkAmocrmAvito";
    import LinkAmocrmWhatsapp from "./integration/LinkAmocrmWhatsapp";
    import LinkAmocrmWebhook from "./integration/LinkAmocrmWebhook";

    export default {
        name: 'LinkIntegration',
        components: {LinkAmocrmWebhook, LinkAmocrmWhatsapp, LinkAmocrmAvito},
        methods: {},
        computed: {}
    }
</script>

<style scoped>
</style>
