<template>
    <div>
        <el-menu mode="horizontal"
                 v-if="user.id">
            <el-menu-item index="1" @click="$router.push('/')">LOGO</el-menu-item>
            <el-sub-menu index="2">
                <template #title>Проекты</template>
                <el-menu-item v-for="(project, index) in projects" :index="`2-${index}`" :key="index"
                              @click="changeProject(project.id)">
                    {{project.name}}
                </el-menu-item>
            </el-sub-menu>
            <el-sub-menu index="3">
                <template #title>Пользователь</template>
                <el-menu-item index="3-1" @click="$router.push('/profile')">Профиль</el-menu-item>
                <el-menu-item index="3-2">item two</el-menu-item>
                <el-menu-item index="3-3" @click="logout">Выйти</el-menu-item>
            </el-sub-menu>
        </el-menu>
    </div>
</template>

<script>
    import {FormObject} from "../utils/form-object";
    import {SET_USER} from "../store/mutations";
    import {GET_PROJECT} from "../store/actions";

    export default {
        name: 'LinkHeader',
        methods: {
            logout() {
                this.$store.commit(SET_USER, new FormObject());
                localStorage.removeItem("access_token");
                this.$router.push('/login')
            },
            changeProject(projectId) {
                this.$store.dispatch(GET_PROJECT, projectId).then(() => {
                    this.$router.push(`/project/${projectId}`)
                }).catch(() => {
                });
            }
        },
        computed: {
            user() {
                return this.$store.getters.user.origin
            },
            projects() {
                return this.$store.getters.projects
            },
        },
    }
</script>

<style scoped>
</style>
