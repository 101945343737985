import {SET_AVITO_INTEGRATIONS} from "../mutations";

const state = {
    avito_integrations: [],
};

const getters = {
    avito_integrations: state => state.avito_integrations || [],
};

const actions = {};

const mutations = {
    [SET_AVITO_INTEGRATIONS]: (state, avito_integrations) => {
        if (Array.isArray(avito_integrations)) {
            state.avito_integrations = avito_integrations;
        }
    },
};

export default {
    state,
    getters,
    actions,
    mutations
}